import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer2";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";
import { Link } from "react-router-dom";

import Bg from "../img/services/bg3.jpg";
import banner from "../img/services/business-background-3.webp";
import banner2 from "../img/services/business-background-4.webp";
import Empresarial from "../components/Empresarial2";

import BtWhatsapp from "../components/ButtonWhatsapp";

const EmpresarialBreadcrumb2 = () => {
  return (
    <>
      <Header />
      <main>
        {/* <Breadcrumb
          menu="Serviços"
          name="Investigação Empresarial" 
          bg={banner2}
        /> */}
        <div id="merox-breadcrumb-area"
          className="merox-breadcrumb-area"
          style={{
            backgroundImage: `url('${banner2}')`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumb-content-box">
                  <h2 className="empresarial-banner">Investigação Empresarial</h2>
                  <ul className="breadcrumb empresarial-banner">
                    <li className="breadcrumb-item">
                      <Link to="">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Serviços</li>
                    <li className="breadcrumb-item active">Investigação Empresarial</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Empresarial />
        <Footer />
      </main>
      <BtWhatsapp />
      <Scrolltop />
    </>
  );
};

export default EmpresarialBreadcrumb2;
